import { createSelector } from "reselect";

import axios from "helpers/interceptor";
import createReducer from "helpers/createReducer";
import URLS from "./urls";

const GET_GROUPS = "vendors/group/GET_GROUPS";
const GET_GROUPS_FULFILLED = "vendors/group/GET_GROUPS_FULFILLED";

export function getGroups(params) {
  return {
    type: GET_GROUPS,
    payload: axios.get(URLS.GROUP, { params }),
  };
}

const defaultState = {
  list: [],
};

function GET_GROUPS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

const handlers = {
  [GET_GROUPS_FULFILLED]: GET_GROUPS_FULFILLED_REDUCER,
};

const groupSelector = (state) => state.vendors.group;

export const groupList = createSelector(
  groupSelector,
  (instance) => instance.list
);

export const groupListByUse = createSelector(groupList, (instance) => {
  const adminGroup = [
    "admin_panel_access",
    "manage_employees",
    "geo_fence_access",
    "job_access",
    "overview",
    "reports",
    "manage_timecards",
    "manage_tracking",
    "work_shift",
    "feedback",
    "department_supervisor",
    "paid_time_off",
  ];
  const adminPanelGroupList =
    instance.length &&
    instance.filter((i) => adminGroup.indexOf(i.group && i.group.name) > -1);

  /*group is an array of keys to be included in this group. instance is the array received from backend containing all the records. First instance array is filtered on the basis of keys present in the array Group and then the instance array is sorted on the basis of index of keys in the array Group*/

  //sorting has been done on frontend

  const appGroup = [
    "mobile_app_access",
    "team_tracking",
    "crew_clock",
    "mileage",
    "manual_mileage",
    "do_not_allow_access_to_timeclock_on_app",
    "create_employee",
    "create_jobs",
  ];
  const appGroupList =
    instance.length &&
    instance
      .filter((i) => appGroup.includes(i.group && i.group.name))
      .sort((a, b) => {
        if (appGroup.indexOf(a.group.name) > appGroup.indexOf(b.group.name))
          return 1;
        else if (
          appGroup.indexOf(a.group.name) < appGroup.indexOf(b.group.name)
        )
          return -1;
      });

  const geofenceGroup = [
    "geo_fence_restriction",
    "geofence_punch_in_restriction",
    "geofence_punch_out_restriction",
    "geofence_meal_start_restriction",
    "geofence_meal_end_restriction",
    "geofence_job_switch_restriction",
  ];
  const geofenceGroupList =
    instance.length &&
    instance
      .filter((i) => geofenceGroup.includes(i.group && i.group.name))
      .sort((a, b) => {
        if (
          geofenceGroup.indexOf(a.group.name) >
          geofenceGroup.indexOf(b.group.name)
        )
          return 1;
        else if (
          geofenceGroup.indexOf(a.group.name) <
          geofenceGroup.indexOf(b.group.name)
        )
          return -1;
      });

  const kioskGroup = [
    "enable_pincode_access",
    "biometric_access",
    "pin_access",
    "kiosk_access",
    "enable_liveness_detection"
  ];
  const kioskGroupList =
    instance.length &&
    instance.filter((i) => kioskGroup.indexOf(i.group && i.group.name) > -1);

  const settingsGroup = [
    "manage_company_settings",
    "organization",
    "manage_payroll",
    "plugin_setting",
  ];
  const settingsGroupList =
    instance.length &&
    instance.filter((i) => settingsGroup.indexOf(i.group && i.group.name) > -1);

  const miscellaneousGroup = [
    "task_time_allocation",
    "feedback_misc",
    "pto_supervisor",
    "communication_center",
    "learning_center",
    "applicant_center",
  ];
  const miscellaneousGroupList =
    instance.length &&
    instance.filter(
      (i) => miscellaneousGroup.indexOf(i.group && i.group.name) > -1
    );

  const schedulingGroup = [
    "timefence_restriction_enabled",
    "timefence_restriction_allow_override",
  ];
  const schedulingGroupList =
    instance.length &&
    instance.filter(
      (i) => schedulingGroup.indexOf(i.group && i.group.name) > -1
    );

  const ptoGroup = ["pto_supervisor", "pto_create", "pto_view"];
  const ptoGroupList =
    instance.length &&
    instance.filter((i) => ptoGroup.indexOf(i.group && i.group.name) > -1);

  const arr = [
    { id: "admin_panel", label: "ADMIN PANEL", groups: adminPanelGroupList },
    { id: "settings", label: "SETTINGS", groups: settingsGroupList },
    { id: "kiosk", label: "KIOSK", groups: kioskGroupList },
    { id: "geofence_group", label: "GEOFENCE", groups: geofenceGroupList },
    { id: "mobile_app", label: "MOBILE APP", groups: appGroupList },
    {
      id: "miscellaneous_group",
      label: "MISCELLANOUS APP",
      groups: miscellaneousGroupList,
    },
    {
      id: "scheduling_group",
      label: "SCHEDULING",
      groups: schedulingGroupList,
    },
    { id: "pto_group", label: "PTO", groups: ptoGroupList },
  ];
  return arr;
});

export default createReducer(defaultState, handlers);
