import React, { Suspense } from "react";

import { createBrowserHistory } from "history";
import { Provider as ReduxProvider } from "react-redux";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import configureStore from "helpers/configureStore";

import MatchWhenAuthorized from "pages/MatchWhenAuthorized";
import MatchAlways from "pages/MatchAlways";

import "./styles/index.global.scss";
import "airbnb-js-shims";
import appRoutes from "./routes";
import MatchWhenUnauthorized from "pages/MatchWhenUnauthorized";
import FullScreenLoaderAtom from "atoms/FullScreenLoader";
import routes from "constants/routes";

const history = createBrowserHistory();

const initialState = window.__INITIAL_STATE__;

const store = configureStore(initialState, history);

const App = () => (
  <BrowserRouter>
    <ReduxProvider store={store}>
      <Suspense
        fallback={<FullScreenLoaderAtom active showReloadButton={false} />}
      >
        <section>
          <ToastContainer hideProgressBar />
          <Routes>
            {appRoutes.map((route) => {
              if (route.private) {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    element={
                      <MatchWhenAuthorized {...route} key={route.path} />
                    }
                  />
                );
              } else if (route.unauth) {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    element={
                      <MatchWhenUnauthorized
                        name={route.name}
                        key={route.path}
                        component={route.component}
                      />
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    element={
                      <MatchAlways
                        key={route.path}
                        component={route.component}
                      />
                    }
                  />
                );
              }
            })}
          </Routes>
        </section>
      </Suspense>
    </ReduxProvider>
  </BrowserRouter>
);

export default App;
