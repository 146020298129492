import React from "react";

import * as UserDucks from "ducks/accounts/user";
import styles from "./styles.module.scss";
import withRouter from "helpers/withRouter";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

const FooterSection = (props, newDashboard) => {
  let startDate;
  let endDate;
  if (
    props?.userCompany.active_payroll &&
    props?.userCompany.active_payroll.is_active
  ) {
    startDate = moment(props?.userCompany.active_payroll.start_datetime);
    endDate = moment(props?.userCompany.active_payroll.end_datetime); //.subtract(1, "days");
  }
  return (
    <>
      {props?.newDashboard && (
        <div
          style={{ position: "absolute", bottom: "42px", padding: "0 2rem",background:'#efefef' }}
        >
          {startDate && endDate ? (
            <span>
              Pay Period: {startDate.format("MMMM D")} to{" "}
              {endDate.format("MMMM D")} (Day{" "}
              {moment().diff(startDate, "days") + 1 >
              endDate.diff(startDate, "days") + 1
                ? endDate.diff(startDate, "days") + 1
                : moment().diff(startDate, "days") + 1}{" "}
              of {endDate.diff(startDate, "days") + 1})
              <u className="payroll-underline">
                <a
                  className="payroll-setting pl-4"
                  href="/payroll/setting/list"
                >
                  View Payroll Settings
                </a>
              </u>
            </span>
          ) : (
            <span>
              There is no default payroll. You could create one{" "}
              <u>
                <Link to="/payroll">here</Link>
              </u>
            </span>
          )}
        </div>
      )}
      <div className={styles.footer} style={{background:'#efefef'}}>
        <div>
          &copy; <span>{new Date().getFullYear()}</span> VendorVer. All rights
          reserved. VendorVer and Worksana are trademarks of VendorVer Inc.
          <span style={{ margin: "10px" }}>
            <a
              href="https://www.worksana.com/terms-and-conditions"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>
          </span>
          <span>
            <a
              href="https://www.worksana.com/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </span>
        </div>
        <small className={styles.footerRight}>
          POWERED BY{" "}
          <a
            href="http://www.thoughts2binary.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            T2B GRANITE
          </a>
        </small>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userCompany: UserDucks.userCompany(state),
});

export default withRouter(connect(mapStateToProps)(FooterSection));
