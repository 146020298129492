import React, { useState, useEffect, useCallback } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";

import * as UserDucks from "ducks/accounts/user";
import * as PaymentDucks from "ducks/vendors/payment";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";

import FullScreenLoaderAtom from "atoms/FullScreenLoader";

import StorageGateway from "lib/storage-gateway";

const MatchWhenAuthorized = (props) => {
  const {
    profile,
    isBusinessAdmin,
    component: Component,
    name,
    accessPerms,
    customerDetail,
    isSubCompany,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const getCustomerDetails = useCallback(async () => {
    if (!isBusinessAdmin) {
      if (profile?.company?.parent_company) {
        await props.PaymentDucks.getCustomer(profile?.company?.parent_company)
          .then(() => {})
          .catch(() => {});
      } else if (profile?.company?.id) {
        await props.PaymentDucks.getCustomer(profile?.company?.id)
          .then(() => {})
          .catch(() => {});
      }
    }
  }, [
    isBusinessAdmin,
    profile?.company?.id,
    profile?.company?.parent_company,
    props.PaymentDucks,
  ]);

  const fetchUserData = useCallback(async () => {
    await props.BasicSettingDucks.getBasicSetting()
      .then(() => {})
      .catch(() => {});
    await props.UserDucks.getProfileUser()
      .then(async () => {
        await getCustomerDetails();

        await Promise.all([
          props.UserDucks.getAccessUser(),
          props.UserDucks.getExtraData(),
        ]).finally(() => {
          setIsLoading(false);
        });
      })
      .catch(() => setIsLoading(false));
  }, [getCustomerDetails, props.BasicSettingDucks, props.UserDucks]);

  useEffect(() => {
    if (!Object.keys(profile).length) {
      fetchUserData();
    }
  }, []);

  const checkAccountValidity = (param) => {
    const accountSuspended =
      customerDetail && customerDetail.commercial_status === "Suspended";

    const cancellationInProgress =
      customerDetail &&
      customerDetail.commercial_status === "Cancellation in progress";

    const noBilling =
      customerDetail && customerDetail.commercial_status === "No Billing";

    const termsAccepted = profile && profile.tc_data;

    if (!isBusinessAdmin) {
      if (termsAccepted) {
        if (name === "accounts.terms&condn")
          return <Navigate to="/dashboard" />;
        return <Component {...param} />;
      }
      if (isSubCompany || noBilling) {
        if (
          name === "vendor.plan" ||
          name === "vendor.invoice" ||
          name === "vendor.payment-method" ||
          name === "vendor.invoice.detail"
        )
          return <Navigate to="/dashboard" />;
        return <Component {...param} />;
      }
      if (accountSuspended) {
        if (name !== "accounts.suspension") {
          return <Navigate to="/accounts/suspension" />;
        }
        return <Component {...param} />;
      } else if (cancellationInProgress) {
        if (name !== "accounts.cancellation") {
          return <Navigate to="/accounts/cancellation" />;
        }
        return <Component {...param} />;
      } else if (!accountSuspended) {
        if (name === "accounts.suspension") return <Navigate to="/dashboard" />;
        return <Component {...param} />;
      } else if (!cancellationInProgress) {
        if (name === "accounts.cancellation")
          return <Navigate to="/dashboard" />;
        return <Component {...param} />;
      } else if (pathNotPermitted(name)) {
        if (name !== "dashboard") return <Navigate to="/dashboard" />;
      } else return <Component {...param} />;
    }
  };

  const checkMobileAndEmailVerification = (param) => {
    const emailVerfied = profile.company && profile.company.is_email_verified;
    const phoneVerfied = profile.company && profile.company.is_phone_verified;
    const passwordExpiry = profile && profile.password_expiry > 90;
    const termsAccepted =
      profile && profile.tc_data && profile.tc_data.tc_agreed;
    const oAuthToken = StorageGateway.get("oAuthToken");

    if (!isBusinessAdmin) {
      if (!emailVerfied && !phoneVerfied) {
        if (name !== "accounts.activate_user")
          return <Navigate to="/accounts/activate-user" />;
        return <Component {...param} />;
      }
      if (!termsAccepted && oAuthToken) {
        if (name !== "accounts.terms&condn")
          return <Navigate to="/accounts/terms&condition" />;
        return <Component {...param} />;
      } else if (passwordExpiry) {
        if (name !== "accounts.change-expired-password") {
          return <Navigate to="/accounts/change-expired-password" />;
        }
      } else if (pathNotPermitted(name)) {
        if (name !== "dashboard") return <Navigate to="/dashboard" />;
      }
      return checkAccountValidity(param);
    } else if (isBusinessAdmin) {
      if (name == "accounts.terms&condn")
        return <Navigate to="/vendor/list/" />;
      if (pathNotPermitted(name)) {
        return <Navigate to="/vendor/list/" />;
      }
      return <Component {...param} />;
    }
  };

  const checkProfile = (param) => {
    const oAuthToken = StorageGateway.get("oAuthToken");
    if (Object.keys(profile).length) {
      return checkMobileAndEmailVerification(param);
    } else if (!Object.keys(profile).length) {
      return <Navigate to="/accounts/login" />;
      cookie.remove("lastActivityTime", { path: "/" });
    }
    return <Navigate to="/accounts/login" />;
  };

  const pathNotPermitted = (routeName) => {
    return accessPerms.filter((item) => item === routeName).length === 0;
  };
  if (!Object.keys(profile).length && isLoading)
    return <FullScreenLoaderAtom active />;
  return checkProfile(props);
};

const mapStateToProps = (state) => ({
  accessPerms: UserDucks.accessPerms(state),
  isBusinessAdmin: UserDucks.isBusinessAdmin(state),
  isPayrollCreated: UserDucks.isPayrollCreated(state),
  profile: UserDucks.profile(state),
  userCompany: UserDucks.userCompany(state),
  customerDetail: PaymentDucks.customerDetail(state),
  isSubCompany: UserDucks.isSubCompany(state),
});

const mapActionsToProps = (dispatch) => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  PaymentDucks: bindActionCreators(PaymentDucks, dispatch),
});

MatchWhenAuthorized.propTypes = {
  accessPerms: PropTypes.array.isRequired,
  component: PropTypes.func.isRequired,
  isBusinessAdmin: PropTypes.bool,
  isPayrollCreated: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  BasicSettingDucks: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(MatchWhenAuthorized);
